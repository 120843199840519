import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as styles from './blog-post.module.css'

const xStyle = '<p class="p--hg u-ls-0 u-color-black-2 mt-3 mt-md-2 mb-5 mb-md-4">'
const BlogPost = ({ data }) => {
  const rawpost = `${data.markdownRemark.html}`
  const post =
    rawpost
      .replace(/<p>/g, xStyle)
      .replace(/<h1>/g, '<h1 class="u-color-black-2">')
      .replace(/<ol>/g, `<ol class=${styles.blogPostOL}`)
      .replace(/<ul>/g, `<ol class=${styles.blogPostUL}`)
      .replace(/<li>/g, `<li class=${styles.blogPostLI}>`)

  return (
    <Layout title={data.markdownRemark.frontmatter.seotitle}
      description={data.markdownRemark.frontmatter.seodescription} >
      <section className="banner banner--alt">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="u-color-secondary u-weight-smb">
                {data.markdownRemark.frontmatter.title}
              </h1>
              <p className="p--hg u-ls-0 u-color-black-2 mt-3 mt-md-2 mb-5 mb-md-4">
                {data.markdownRemark.frontmatter.description}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
                <div dangerouslySetInnerHTML={{ __html: post }}></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`query BlogPostQuery($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
      description
      seotitle
      seodescription
      featuredimage {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  }
}
`

export default BlogPost;